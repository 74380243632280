import { logger } from '@hatchd/utils';
import { captureMessage } from '@sentry/nextjs';
import { ErrorMessageType, IconProps } from 'components/ui';
import format from 'date-fns/format';
import flat from 'flat';
import { nanoid } from 'nanoid';
import { signOut } from 'next-auth/react';
import { destroyCookie } from 'nookies';
import { BUYER_ORG_STORAGE_KEY, DATE_FORMAT, PATHS } from 'settings/config';
import { globalTheme } from 'styles/global-theme';
import {
  BookingPropertyStaffUser,
  BuyerSellerUserInfo,
  ContactStatuses,
  ListingPublicStatusEnum,
  ListingVisibilityEnum,
  OrgAddress,
  OrgDetail,
  User,
  UserDetailed,
} from './api/generated';
import { PriceEnum } from '../components/marketplace-listing/listing-form-schema';

export const __DEV__ = process.env.NEXT_PUBLIC_ENV === 'development';

export const fetchData = async (input: RequestInfo, init?: RequestInit) => {
  const res = await fetch(input, init);
  return await res.json();
};

/**
 * Re-format the errors returned from the API to an array of objects for ease of use in react-hook-form error messages
 */
export function getFormFieldErrorsFromResponse<Data>(errors: {
  [P in keyof Data]: string[];
}): {
  field: Extract<keyof Data, string>;
  message: string;
}[] {
  // Ensure errors object has been flattened before trying to loop over each error
  const flattenedObj = flat<typeof errors, { [key: string]: string[] }>(
    errors,
    {
      safe: true,
    }
  );

  return Object.entries(flattenedObj).map(([field, message]: any) => ({
    field,
    message: Array.isArray(message) ? message.join(',') : message,
  }));
}

/** Boolean, whether the code is running in a browser or server-side */
export const isBrowser = typeof window !== 'undefined';

/** Sign out the current user and call the relevant endpoint to clean up the JWT */
export const signOutCurrentUser = async () => {
  // Call the local endpoint to invalidate the users refresh token, we don't care if this fails as it shouldn't prevent the user from logging out
  await fetch(PATHS.api.invalidateRefreshToken);

  destroyCookie(null, BUYER_ORG_STORAGE_KEY, { path: '/' });

  // Call the next-auth method to clear the current user session, this reloads the page when complete which will also clear out zustand + react-query caches
  await signOut({ redirect: true });
};

// Convert a file to base64 string
export function toBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Convert a url to file
export function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export function getLivestockTypeIconName(type: string): IconProps['component'] {
  switch (type) {
    case 'CT':
      return 'CattleIcon';
    case 'SH':
      return 'SheepIcon';
    case 'GT':
      return 'GoatIcon';
    default:
      return 'CattleIcon';
  }
}

export function getLivestockTypeName(type: string) {
  switch (type) {
    case 'CT':
      return 'Cattle';
    case 'SH':
      return 'Sheep';
    case 'GT':
      return 'Goat';
    default:
      return 'Cattle';
  }
}

export function getStatusBackgroundColor(
  status: ContactStatuses | string
): keyof typeof globalTheme.colors {
  switch (status) {
    case ContactStatuses.Pending:
      return 'yellow';
    case ContactStatuses.Rejected:
      return 'lightPink';
    case ContactStatuses.Blocked:
      return 'lightPink';
      return 'lightPink';
    default:
      return 'neutral';
  }
}

export function getStatusColor(
  status: ContactStatuses | string
): keyof typeof globalTheme.colors {
  switch (status) {
    case ContactStatuses.Pending:
      return 'orange';
    case ContactStatuses.Rejected:
      return 'pink';
    case ContactStatuses.Blocked:
      return 'pink';
    default:
      return 'black';
  }
}

export function getUserName(
  user?:
    | BookingPropertyStaffUser
    | User
    | UserDetailed
    | BuyerSellerUserInfo
    | null
) {
  if (!user?.firstname && !user?.lastname) {
    return '';
  }

  return `${user?.firstname || ''} ${user?.lastname || ''}`;
}

export function formatDeliveryDate(
  date?:
    | {
        date_from: string;
        date_to: string;
      }
    | { date: string }
    | {}
) {
  if (date && 'date_from' in date && 'date_to' in date) {
    return `${format(new Date(date.date_from), DATE_FORMAT.long)} - ${format(
      new Date(date.date_to),
      DATE_FORMAT.long
    )}`;
  }
  if (date && hasOwnProperty(date, 'date')) {
    return format(new Date(date.date as string), DATE_FORMAT.long);
  }
  return 'No date specified';
}

export function formatListingDateRange(
  date_from: string,
  date_to: string | null
) {
  const from = new Date(date_from);

  if (!date_to) {
    return format(from, DATE_FORMAT.listing);
  }

  const to = new Date(date_to);

  // If the dates are the same year then don't include the first year.
  if (from.getFullYear() === to.getFullYear()) {
    return `${format(from, DATE_FORMAT.listing_short)} to ${format(
      to,
      DATE_FORMAT.listing
    )}`;
  }

  return `${format(from, DATE_FORMAT.listing)} to ${format(
    to,
    DATE_FORMAT.listing
  )}`;
}

// Used for hookform validation
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;

export function toTitleCase(word: string) {
  return word.charAt(0).toUpperCase() + word.substr(1).toLocaleLowerCase();
}

/** Narrow the type of an object based on the presence of a property
 *  https://fettblog.eu/typescript-hasownproperty/
 */
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

/**
 * Takes an object and removes any empty/undefined/null values
 * @param obj The object to remove empty properties from
 * @returns Modified object
 */
export function removeEmpty<T extends { [key: string]: any }>(
  obj: T
): Partial<T> {
  let finalObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      const nestedObj = removeEmpty(obj[key]);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
}

export function getPriceTypeData(priceTypeId: string) {
  let name = '';
  let description = '';
  let unit = '';

  switch (priceTypeId) {
    case 'PER_HEAD':
      name = 'Per head';
      description = 'Dollars per head of livestock';
      unit = 'head';
      break;
    case 'LIVEWEIGHT':
      name = 'Live weight';
      description = 'Dollars per kg based on live weight';
      unit = 'kg';
      break;
    case 'HSCW':
      name = 'HSCW';
      description = 'Dollars per kg based on carcass weight';
      unit = 'kg';
      break;
    default:
      break;
  }

  return {
    name,
    description,
    unit,
  };
}

export function getPriceOptionData(priceOption: string) {
  switch (priceOption) {
    case 'RANGE':
      return 'Price range';
      break;
    case 'POA':
      return 'Price on application';
      break;
    case 'MARKET_RATE':
      return 'Market rate';
    case 'OTHER':
      return 'Other';
      break;
  }
}

export function formatAUD(
  number: string | number,
  fractions = true,
  isCurrency = true
) {
  return new Intl.NumberFormat('en-AU', {
    style: isCurrency ? 'currency' : 'decimal',
    currency: 'AUD',
    maximumFractionDigits: fractions ? 2 : 0,
    // minimumFractionDigits cannot be higher than the maximum value.
    minimumFractionDigits: fractions ? 2 : 0,
  }).format(Number(number));
}

export function parseStringAsBooleanOrNull(string: string) {
  switch (string) {
    case 'null':
      return null;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return string;
  }
}

/**
 * Get the overall min/max values for a given array of array of numbers
 * @param numbers An array of array of numbers, assumed to be min/max values
 * @returns A tuple with the min and max numbers from the input array of arrays
 */
export function getMinMaxFromNumberArrays(numbers: number[][]) {
  return numbers.reduce<number[]>((prev, current) => {
    const [min, max] = current;

    if (prev.length > 0) {
      const [prevMin, prevMax] = prev;
      return [min < prevMin ? min : prevMin, max > prevMax ? max : prevMax];
    }

    return [min, max];
  }, []);
}

export function parseStringAsNumberOrNull(string?: string) {
  if (string) {
    return parseFloat(string);
  } else {
    return null;
  }
}

export function formatFirstLastNameShort(user: BuyerSellerUserInfo) {
  const firstInitial = user.firstname?.slice(0, 1);
  const lastName = user.lastname;
  return [firstInitial, lastName].join('. ');
}

/**
 * Returns a comma separated list of items, taking into account any that may be undefined
 * @param args - Variable list of items passed to this function
 * @returns string
 */
export function commaSeparateItems(...args: (string | null | undefined)[]) {
  return args.filter((item) => !!item).join(', ');
}

export function formatAddress(address: OrgAddress) {
  const addressParts = [
    address.address,
    address.suburb,
    address.state,
    address.postcode,
  ];
  return commaSeparateItems(...addressParts);
}

/** Check if a given number based limit would allow the user to perform an action */
export function checkNumberLimitAllowed(limit?: number | null) {
  if (limit === null || (typeof limit === 'number' && limit > 0)) {
    return true;
  } else {
    return false;
  }
}

/** Check if a specific limit's quota has been exceeded */
export function isLimitExceeded(currentCount: number, limit?: number | null) {
  if (limit === null) return false;
  if (typeof limit === 'number' && currentCount >= limit) {
    return true;
  } else {
    return false;
  }
}

export function getSubscriptionPriceAUD(priceCents?: number) {
  if (priceCents === undefined) return '';
  return formatAUD(priceCents / 100, false);
}

const preserveUndefined = (key: string, value: unknown) =>
  typeof value === 'undefined' ? null : value;

export const errorLogger = (
  message: string,
  object?: { [key: string]: unknown }
) => {
  const stringObject = JSON.stringify(object, preserveUndefined);

  if (process.env.NODE_ENV === 'development') {
    logger.error(`${message} ${stringObject}`);
  } else {
    captureMessage(`${message} ${stringObject}`);
  }
};

/** Type guard to check if a given element is defined, useful for filters when the item you are filtering may be undefined */
export function isDefined<T>(item: T | undefined): item is T {
  return !!item;
}

/** Generate a unique ID for new fields */
export function getNewFieldId() {
  const id = `NEW_${nanoid()}`;
  return id;
}

/**
 * Scroll to first error in the form
 * @param elem Form element
 * @return Scroll to the first error
 */
export const formScrollToErrors = (elem: HTMLFormElement | null) => {
  setTimeout(() => {
    const firstErrorEl = elem?.querySelector(`[data-state='error']`);
    const isPdfUploadError =
      firstErrorEl?.getAttribute('data-type') === ErrorMessageType.pdfError;

    if (isPdfUploadError) {
      // If PDF upload error, scroll to it's parent element
      firstErrorEl?.parentElement &&
        firstErrorEl?.parentElement.scrollIntoView();
    } else {
      firstErrorEl?.scrollIntoView();
    }
  }, 500);
};

/** Convert an 'em' unit string value to a number in pixels */
export const convertEmToPixels = (em: string) => {
  if (em.slice(-2) !== 'em') {
    return null;
  }
  const $em = parseFloat(em.slice(0, -2)); // Clean and convert the string to a number
  const context = parseFloat(window.getComputedStyle(document.body).fontSize);
  return $em * context;
};

export const getPriceType = (getPriceType: string) => {
  switch (getPriceType) {
    case 'PER_HEAD':
      return '/head';
      break;
    case 'LIVEWEIGHT':
      return '/kg liveweight';
      break;
    case 'HSCW':
      return '/kg HSCW';
      break;
  }
};

export function getInlinePrices(prices: {
  HSCW?: number;
  LIVEWEIGHT?: number;
  PER_HEAD?: number;
}): string {
  let price: string = '';
  const append: string = ' - ';

  if (prices.PER_HEAD) {
    price = formatAUD(prices?.PER_HEAD, false) + '/head';
  }
  if (prices.HSCW) {
    price =
      price +
      `${prices?.PER_HEAD ? append : ''}` +
      formatAUD(prices?.HSCW) +
      '/kg HSCW ';
  }
  if (prices.LIVEWEIGHT) {
    price =
      price +
      `${prices?.PER_HEAD || prices?.HSCW ? append : ''}` +
      formatAUD(prices?.LIVEWEIGHT) +
      '/kg liveweight ';
  }
  return price;
}

export function formattedSoldPrices(soldPrice: string, priceType: string) {
  if (priceType === 'PER_HEAD') {
    return formatAUD(soldPrice, false);
  } else {
    return formatAUD(soldPrice);
  }
}

export const getTagStatus = (
  status: ListingPublicStatusEnum | null,
  visibility?: ListingVisibilityEnum
): { text: string } => {
  if (status) {
    switch (status) {
      case ListingPublicStatusEnum.Approved:
        return {
          text: 'Public',
        };
      case ListingPublicStatusEnum.Draft:
        return {
          text: 'Draft',
        };
      case ListingPublicStatusEnum.Review:
        return {
          text: 'Under review',
        };
      case ListingPublicStatusEnum.Rejected:
        return {
          text: 'Rejected',
        };
      default:
        return {
          text: 'Draft',
        };
    }
  } else {
    return {
      text: 'Draft',
    };
  }
};

export function getTagStatusBackgroundColor(
  status: ListingPublicStatusEnum | null,
  visibility?: ListingVisibilityEnum
): keyof typeof globalTheme.colors {
  if (status) {
    switch (status) {
      case ListingPublicStatusEnum.Approved:
        return 'darkBlue';
      case ListingPublicStatusEnum.Draft:
        return 'lightBlue';
      case ListingPublicStatusEnum.Review:
        return 'yellow';
      case ListingPublicStatusEnum.Rejected:
        return 'lightRed';
      default:
        return 'yellow';
    }
  }

  return 'yellow';
}

export function getTagStatusColor(
  status: ListingPublicStatusEnum | null,
  visibility?: ListingVisibilityEnum
): keyof typeof globalTheme.colors {
  if (status) {
    switch (status) {
      case ListingPublicStatusEnum.Approved:
        return 'white';
      case ListingPublicStatusEnum.Draft:
        return 'blue';
      case ListingPublicStatusEnum.Review:
        return 'orange';
      case ListingPublicStatusEnum.Rejected:
        return 'white';
      default:
        return 'orange';
    }
  }

  return 'orange';
}

//  Check if a free tier
export function isFreePlan(org: OrgDetail | undefined) {
  if (!org?.active_subscription || !org?.subscription) {
    return true;
  } else {
    return false;
  }
}

export const snakeCaseToTitleCase = (target: string): string =>
  target.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d));

export const pricingOptionFormatter = (price: string) => {
  if (!price) {
    return;
  } else if (price === 'poa') {
    return price.toUpperCase();
  } else {
    return toTitleCase(price);
  }
};

export const australianPhoneNumberFormatter = (number: string) => {
  const strippedNumber = number.replace(/\s/g, '');
  if (/^(\+61)/.test(strippedNumber)) {
    return strippedNumber.replace(/^(\+61)/, '0');
  } else {
    return strippedNumber;
  }
};

export const OFFER_RECEIVED = 'OFFER RECEIVED:';
export const CALLBACK_REQUEST = 'CALLBACK REQUEST:';
