/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ListingBookmark } from '../model';
// @ts-ignore
import { ListingEnquiry } from '../model';
// @ts-ignore
import { ListingEnquiryCreate } from '../model';
// @ts-ignore
import { ListingRead } from '../model';
// @ts-ignore
import { PaginatedListingReadList } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * PublicMarketplaceListingApi - axios parameter creator
 * @export
 */
export const PublicMarketplaceListingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create or update a Bookmark against the Listing.
         * @param {string} listingId 
         * @param {ListingBookmark} listingBookmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingBookmarkCreate: async (listingId: string, listingBookmark: ListingBookmark, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('marketplaceListingBookmarkCreate', 'listingId', listingId)
            // verify required parameter 'listingBookmark' is not null or undefined
            assertParamExists('marketplaceListingBookmarkCreate', 'listingBookmark', listingBookmark)
            const localVarPath = `/v2/marketplace/listing/{listing_id}/bookmark`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingBookmark, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Enquiry for a Listing.
         * @param {string} listingId 
         * @param {ListingEnquiryCreate} listingEnquiryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingEnquiryCreate: async (listingId: string, listingEnquiryCreate: ListingEnquiryCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('marketplaceListingEnquiryCreate', 'listingId', listingId)
            // verify required parameter 'listingEnquiryCreate' is not null or undefined
            assertParamExists('marketplaceListingEnquiryCreate', 'listingEnquiryCreate', listingEnquiryCreate)
            const localVarPath = `/v2/marketplace/listing/{listing_id}/enquiry`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingEnquiryCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The public endpoint for retrieving all the current public listings.
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceMax] Maximum price
         * @param {string} [priceMin] Minimum price
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [quantityMax] Maximum quantity
         * @param {string} [quantityMin] Minimum quantity
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingList: async (bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceMax?: string, priceMin?: string, priceType?: string, product?: string, quantity?: string, quantityMax?: string, quantityMin?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/marketplace/listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bookmark !== undefined) {
                localVarQueryParameter['bookmark'] = bookmark;
            }

            if (network !== undefined) {
                localVarQueryParameter['network'] = network;
            }

            if (org !== undefined) {
                localVarQueryParameter['org'] = org;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['price_max'] = priceMax;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['price_min'] = priceMin;
            }

            if (priceType !== undefined) {
                localVarQueryParameter['price_type'] = priceType;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }

            if (quantityMax !== undefined) {
                localVarQueryParameter['quantity_max'] = quantityMax;
            }

            if (quantityMin !== undefined) {
                localVarQueryParameter['quantity_min'] = quantityMin;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (stockAge !== undefined) {
                localVarQueryParameter['stock_age'] = stockAge;
            }

            if (stockCat !== undefined) {
                localVarQueryParameter['stock_cat'] = stockCat;
            }

            if (stockGender !== undefined) {
                localVarQueryParameter['stock_gender'] = stockGender;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint behaves the same as the Public Listing endpoint except for two differences:  1. It is filtered by the Organisation in the path  2. If the Organisation is a Head Org then all Public Listings within it\'s Networks are also returned.
         * @summary The public endpoint for retrieving all the current Public Listings filtered by the Org
         * @param {string} orgId 
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingOrgList: async (orgId: string, bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceType?: string, product?: string, quantity?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('marketplaceListingOrgList', 'orgId', orgId)
            const localVarPath = `/v2/marketplace/listing/org/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bookmark !== undefined) {
                localVarQueryParameter['bookmark'] = bookmark;
            }

            if (network !== undefined) {
                localVarQueryParameter['network'] = network;
            }

            if (org !== undefined) {
                localVarQueryParameter['org'] = org;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceType !== undefined) {
                localVarQueryParameter['price_type'] = priceType;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sold !== undefined) {
                localVarQueryParameter['sold'] = sold;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (stockAge !== undefined) {
                localVarQueryParameter['stock_age'] = stockAge;
            }

            if (stockCat !== undefined) {
                localVarQueryParameter['stock_cat'] = stockCat;
            }

            if (stockGender !== undefined) {
                localVarQueryParameter['stock_gender'] = stockGender;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The public endpoint for retrieving the latest APPROVED version of the listing, otherwise 404
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingRetrieve: async (listingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('marketplaceListingRetrieve', 'listingId', listingId)
            const localVarPath = `/v2/marketplace/listing/{listing_id}`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicMarketplaceListingApi - functional programming interface
 * @export
 */
export const PublicMarketplaceListingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicMarketplaceListingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create or update a Bookmark against the Listing.
         * @param {string} listingId 
         * @param {ListingBookmark} listingBookmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceListingBookmarkCreate(listingId: string, listingBookmark: ListingBookmark, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingBookmark>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceListingBookmarkCreate(listingId, listingBookmark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Enquiry for a Listing.
         * @param {string} listingId 
         * @param {ListingEnquiryCreate} listingEnquiryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceListingEnquiryCreate(listingId: string, listingEnquiryCreate: ListingEnquiryCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingEnquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceListingEnquiryCreate(listingId, listingEnquiryCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The public endpoint for retrieving all the current public listings.
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceMax] Maximum price
         * @param {string} [priceMin] Minimum price
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [quantityMax] Maximum quantity
         * @param {string} [quantityMin] Minimum quantity
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceListingList(bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceMax?: string, priceMin?: string, priceType?: string, product?: string, quantity?: string, quantityMax?: string, quantityMin?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListingReadList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceListingList(bookmark, network, org, page, pageSize, price, priceMax, priceMin, priceType, product, quantity, quantityMax, quantityMin, search, sold, state, stockAge, stockCat, stockGender, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint behaves the same as the Public Listing endpoint except for two differences:  1. It is filtered by the Organisation in the path  2. If the Organisation is a Head Org then all Public Listings within it\'s Networks are also returned.
         * @summary The public endpoint for retrieving all the current Public Listings filtered by the Org
         * @param {string} orgId 
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceListingOrgList(orgId: string, bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceType?: string, product?: string, quantity?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListingReadList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceListingOrgList(orgId, bookmark, network, org, page, pageSize, price, priceType, product, quantity, search, sold, state, stockAge, stockCat, stockGender, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The public endpoint for retrieving the latest APPROVED version of the listing, otherwise 404
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceListingRetrieve(listingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceListingRetrieve(listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicMarketplaceListingApi - factory interface
 * @export
 */
export const PublicMarketplaceListingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicMarketplaceListingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create or update a Bookmark against the Listing.
         * @param {string} listingId 
         * @param {ListingBookmark} listingBookmark 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingBookmarkCreate(listingId: string, listingBookmark: ListingBookmark, options?: any): AxiosPromise<ListingBookmark> {
            return localVarFp.marketplaceListingBookmarkCreate(listingId, listingBookmark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Enquiry for a Listing.
         * @param {string} listingId 
         * @param {ListingEnquiryCreate} listingEnquiryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingEnquiryCreate(listingId: string, listingEnquiryCreate: ListingEnquiryCreate, options?: any): AxiosPromise<ListingEnquiry> {
            return localVarFp.marketplaceListingEnquiryCreate(listingId, listingEnquiryCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The public endpoint for retrieving all the current public listings.
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceMax] Maximum price
         * @param {string} [priceMin] Minimum price
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [quantityMax] Maximum quantity
         * @param {string} [quantityMin] Minimum quantity
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingList(bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceMax?: string, priceMin?: string, priceType?: string, product?: string, quantity?: string, quantityMax?: string, quantityMin?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options?: any): AxiosPromise<PaginatedListingReadList> {
            return localVarFp.marketplaceListingList(bookmark, network, org, page, pageSize, price, priceMax, priceMin, priceType, product, quantity, quantityMax, quantityMin, search, sold, state, stockAge, stockCat, stockGender, type, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint behaves the same as the Public Listing endpoint except for two differences:  1. It is filtered by the Organisation in the path  2. If the Organisation is a Head Org then all Public Listings within it\'s Networks are also returned.
         * @summary The public endpoint for retrieving all the current Public Listings filtered by the Org
         * @param {string} orgId 
         * @param {string} [bookmark] Filter by Bookmark Type. Multiple values must be comma separated
         * @param {string} [network] Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
         * @param {string} [org] Filter by Organisation id
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {string} [price] Filter by pricing using a min and/or max value
         * @param {string} [priceType] Filter by price type
         * @param {string} [product] product
         * @param {string} [quantity] Filter by quantity using a min and/or max value
         * @param {string} [search] Filter by a set of keywords
         * @param {string} [sold] Filter by if the Listing is sold or not
         * @param {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'} [state] Filter by state of the Listings. Multiple values must be comma separated
         * @param {string} [stockAge] Filter by Stock Age. Multiple values must be comma separated
         * @param {string} [stockCat] Filter by Stock Categories. Multiple values must be comma separated
         * @param {string} [stockGender] Filter by Stock Gender. Multiple values must be comma separated
         * @param {'SELL' | 'BUY'} [type] Filter by Listing type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingOrgList(orgId: string, bookmark?: string, network?: string, org?: string, page?: number, pageSize?: number, price?: string, priceType?: string, product?: string, quantity?: string, search?: string, sold?: string, state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA', stockAge?: string, stockCat?: string, stockGender?: string, type?: 'SELL' | 'BUY', options?: any): AxiosPromise<PaginatedListingReadList> {
            return localVarFp.marketplaceListingOrgList(orgId, bookmark, network, org, page, pageSize, price, priceType, product, quantity, search, sold, state, stockAge, stockCat, stockGender, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The public endpoint for retrieving the latest APPROVED version of the listing, otherwise 404
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceListingRetrieve(listingId: string, options?: any): AxiosPromise<ListingRead> {
            return localVarFp.marketplaceListingRetrieve(listingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for marketplaceListingBookmarkCreate operation in PublicMarketplaceListingApi.
 * @export
 * @interface PublicMarketplaceListingApiMarketplaceListingBookmarkCreateRequest
 */
export interface PublicMarketplaceListingApiMarketplaceListingBookmarkCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingBookmarkCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingBookmark}
     * @memberof PublicMarketplaceListingApiMarketplaceListingBookmarkCreate
     */
    readonly listingBookmark: ListingBookmark
}

/**
 * Request parameters for marketplaceListingEnquiryCreate operation in PublicMarketplaceListingApi.
 * @export
 * @interface PublicMarketplaceListingApiMarketplaceListingEnquiryCreateRequest
 */
export interface PublicMarketplaceListingApiMarketplaceListingEnquiryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingEnquiryCreate
     */
    readonly listingId: string

    /**
     * 
     * @type {ListingEnquiryCreate}
     * @memberof PublicMarketplaceListingApiMarketplaceListingEnquiryCreate
     */
    readonly listingEnquiryCreate: ListingEnquiryCreate
}

/**
 * Request parameters for marketplaceListingList operation in PublicMarketplaceListingApi.
 * @export
 * @interface PublicMarketplaceListingApiMarketplaceListingListRequest
 */
export interface PublicMarketplaceListingApiMarketplaceListingListRequest {
    /**
     * Filter by Bookmark Type. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly bookmark?: string

    /**
     * Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly network?: string

    /**
     * Filter by Organisation id
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly org?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly pageSize?: number

    /**
     * Filter by pricing using a min and/or max value
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly price?: string

    /**
     * Maximum price
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly priceMax?: string

    /**
     * Minimum price
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly priceMin?: string

    /**
     * Filter by price type
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly priceType?: string

    /**
     * product
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly product?: string

    /**
     * Filter by quantity using a min and/or max value
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly quantity?: string

    /**
     * Maximum quantity
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly quantityMax?: string

    /**
     * Minimum quantity
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly quantityMin?: string

    /**
     * Filter by a set of keywords
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly search?: string

    /**
     * Filter by if the Listing is sold or not
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly sold?: string

    /**
     * Filter by state of the Listings. Multiple values must be comma separated
     * @type {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'

    /**
     * Filter by Stock Age. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly stockAge?: string

    /**
     * Filter by Stock Categories. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly stockCat?: string

    /**
     * Filter by Stock Gender. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly stockGender?: string

    /**
     * Filter by Listing type
     * @type {'SELL' | 'BUY'}
     * @memberof PublicMarketplaceListingApiMarketplaceListingList
     */
    readonly type?: 'SELL' | 'BUY'
}

/**
 * Request parameters for marketplaceListingOrgList operation in PublicMarketplaceListingApi.
 * @export
 * @interface PublicMarketplaceListingApiMarketplaceListingOrgListRequest
 */
export interface PublicMarketplaceListingApiMarketplaceListingOrgListRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly orgId: string

    /**
     * Filter by Bookmark Type. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly bookmark?: string

    /**
     * Filter by Network id. Multiple values must be comma separated. Use &#x60;all&#x60; to return Listings that are only shared with a Network.
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly network?: string

    /**
     * Filter by Organisation id
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly org?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly pageSize?: number

    /**
     * Filter by pricing using a min and/or max value
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly price?: string

    /**
     * Filter by price type
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly priceType?: string

    /**
     * product
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly product?: string

    /**
     * Filter by quantity using a min and/or max value
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly quantity?: string

    /**
     * Filter by a set of keywords
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly search?: string

    /**
     * Filter by if the Listing is sold or not
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly sold?: string

    /**
     * Filter by state of the Listings. Multiple values must be comma separated
     * @type {'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly state?: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA'

    /**
     * Filter by Stock Age. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly stockAge?: string

    /**
     * Filter by Stock Categories. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly stockCat?: string

    /**
     * Filter by Stock Gender. Multiple values must be comma separated
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly stockGender?: string

    /**
     * Filter by Listing type
     * @type {'SELL' | 'BUY'}
     * @memberof PublicMarketplaceListingApiMarketplaceListingOrgList
     */
    readonly type?: 'SELL' | 'BUY'
}

/**
 * Request parameters for marketplaceListingRetrieve operation in PublicMarketplaceListingApi.
 * @export
 * @interface PublicMarketplaceListingApiMarketplaceListingRetrieveRequest
 */
export interface PublicMarketplaceListingApiMarketplaceListingRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicMarketplaceListingApiMarketplaceListingRetrieve
     */
    readonly listingId: string
}

/**
 * PublicMarketplaceListingApi - object-oriented interface
 * @export
 * @class PublicMarketplaceListingApi
 * @extends {BaseAPI}
 */
export class PublicMarketplaceListingApi extends BaseAPI {
    /**
     * 
     * @summary Create or update a Bookmark against the Listing.
     * @param {PublicMarketplaceListingApiMarketplaceListingBookmarkCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMarketplaceListingApi
     */
    public marketplaceListingBookmarkCreate(requestParameters: PublicMarketplaceListingApiMarketplaceListingBookmarkCreateRequest, options?: any) {
        return PublicMarketplaceListingApiFp(this.configuration).marketplaceListingBookmarkCreate(requestParameters.listingId, requestParameters.listingBookmark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Enquiry for a Listing.
     * @param {PublicMarketplaceListingApiMarketplaceListingEnquiryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMarketplaceListingApi
     */
    public marketplaceListingEnquiryCreate(requestParameters: PublicMarketplaceListingApiMarketplaceListingEnquiryCreateRequest, options?: any) {
        return PublicMarketplaceListingApiFp(this.configuration).marketplaceListingEnquiryCreate(requestParameters.listingId, requestParameters.listingEnquiryCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The public endpoint for retrieving all the current public listings.
     * @param {PublicMarketplaceListingApiMarketplaceListingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMarketplaceListingApi
     */
    public marketplaceListingList(requestParameters: PublicMarketplaceListingApiMarketplaceListingListRequest = {}, options?: any) {
        return PublicMarketplaceListingApiFp(this.configuration).marketplaceListingList(requestParameters.bookmark, requestParameters.network, requestParameters.org, requestParameters.page, requestParameters.pageSize, requestParameters.price, requestParameters.priceMax, requestParameters.priceMin, requestParameters.priceType, requestParameters.product, requestParameters.quantity, requestParameters.quantityMax, requestParameters.quantityMin, requestParameters.search, requestParameters.sold, requestParameters.state, requestParameters.stockAge, requestParameters.stockCat, requestParameters.stockGender, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint behaves the same as the Public Listing endpoint except for two differences:  1. It is filtered by the Organisation in the path  2. If the Organisation is a Head Org then all Public Listings within it\'s Networks are also returned.
     * @summary The public endpoint for retrieving all the current Public Listings filtered by the Org
     * @param {PublicMarketplaceListingApiMarketplaceListingOrgListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMarketplaceListingApi
     */
    public marketplaceListingOrgList(requestParameters: PublicMarketplaceListingApiMarketplaceListingOrgListRequest, options?: any) {
        return PublicMarketplaceListingApiFp(this.configuration).marketplaceListingOrgList(requestParameters.orgId, requestParameters.bookmark, requestParameters.network, requestParameters.org, requestParameters.page, requestParameters.pageSize, requestParameters.price, requestParameters.priceType, requestParameters.product, requestParameters.quantity, requestParameters.search, requestParameters.sold, requestParameters.state, requestParameters.stockAge, requestParameters.stockCat, requestParameters.stockGender, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The public endpoint for retrieving the latest APPROVED version of the listing, otherwise 404
     * @param {PublicMarketplaceListingApiMarketplaceListingRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMarketplaceListingApi
     */
    public marketplaceListingRetrieve(requestParameters: PublicMarketplaceListingApiMarketplaceListingRetrieveRequest, options?: any) {
        return PublicMarketplaceListingApiFp(this.configuration).marketplaceListingRetrieve(requestParameters.listingId, options).then((request) => request(this.axios, this.basePath));
    }
}
